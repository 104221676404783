/*
  销售业绩统计(新)
*/

<template>
  <div>
    <a-form layout="inline">
      <a-form-item>
        <label>时间段：</label>
        <a-range-picker :disabledDate="disabledDate" :defaultValue="[moment(startDate, dateFormat), moment(endDate, dateFormat)]" @change="createChange" size="small" />
      </a-form-item>
      <!-- 月份 -->
      <a-form-item>
        <a-button type="primary" style="marginRight: 5px;" v-for="(item, index) in monthDataList" :key="index" :disabled="item > new Date().getMonth() + 1" @click="changeMonthClick(item)">
          {{item + '月'}}
        </a-button>
      </a-form-item>
      <!-- 顾问区域分公司 -->
      <a-form-item>
        <label>分公司：</label>
        <a-select placeholder="请选择区域/分公司" v-model="insetData.filialeType" style="width: 200px" allowClear @change="filialeChange" @focus="filialeFocus">
          <a-select-option v-for="item in companyArr" :key="item">{{ item }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 顾问组别 -->
      <a-form-item>
        <label>小组：</label>
        <a-select placeholder="请选择小组" v-model="insetData.groupeType" style="width: 200px" allowClear @change="groupeChange">
          <a-select-option v-for="item in groupArr" :key="item.groupId">{{ item.groupName }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 顾问姓名 -->
      <a-form-item>
        <label>顾问：</label>
        <a-select placeholder="请选择顾问" v-model="insetData.counselorType" style="width: 200px" allowClear @change="counselorChange">
          <a-select-option v-for="item in counselorArr" :key="item.sysId">{{ item.nickName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" icon="search" @click="inquireClick('1')">查询</a-button>
        <a-button style="marginLeft: 15px;" @click="insetClick('2')">重置</a-button>
      </a-form-item>
      <a-form-item v-if="isShowBtn">
        <a-upload
          name="file"
          :multiple="true"
          :showUploadList="false"
          :before-upload="beforeUpload"
          :action="uploadUrl"
          @change="handleChange"
          :data="{ tenantId }"
          :headers="{
            'module' : 'bi',
            'app-id': '6e70493db301494692779b53f07ff7c5',
            'timestamps': new Date().getTime(),
            'version': 'v0.0.1'
          }"
        >
        <a-button type="primary" style="marginLeft: 15px;">导入服务费目标</a-button>
      </a-upload>
        <a-button style="margin-left: 20px" type="primary" @click="exportTargetClick">导出目标模板</a-button>
      </a-form-item>
    </a-form>
    <div>
      <a-button type="primary" style="marginRight: 15px;" @click="exportClick">导出Excel</a-button>
      <a-button v-if="Number(userInfo.jobLevel) === 1000" type="primary" icon="download" style="marginRight: 15px;" @click="downRatioTemplate">分成比例模板</a-button>
      <a-upload
          name="file"
          :multiple="false"
          :showUploadList="false"
          :before-upload="beforeUpload"
          :action="uploadRatioUrl"
          @change="handleChange"
          :data="{ tenantId }"
          :headers="{
            'module' : 'bi',
            'app-id': '6e70493db301494692779b53f07ff7c5',
            'timestamps': new Date().getTime(),
            'version': 'v0.0.1'
          }"
        >
        <a-button v-if="Number(userInfo.jobLevel) === 1000" type="primary">导入分成比例</a-button>
      </a-upload>
      <a-button v-if="Number(userInfo.jobLevel) === 1000" type="primary" style="marginLeft: 15px;" @click="accountingOp">分成核算</a-button>
    </div>
    <div>

    </div>
    <a-tabs defaultActiveKey="1" @change="tabChange">
      <a-tab-pane tab="按区域" key="1">
        <a-spin tip="加载中..." :spinning="loadingData">
          <a-table :columns="columnsa" size="middle" :dataSource="tableData" :pagination="false" :customHeaderRow="branchNameChange" :scroll="{ x: 9000 }" :bordered="true" rowKey="groupId" />
        </a-spin>
        <!-- 分页部分 -->
        <!-- <div class="pageClass">
          <a-pagination :total="pageTotal" :showTotal="total => `共 ${pageTotal} 条`" showSizeChanger showQuickJumper @change="pageChange" @showSizeChange="pageSizePage" />
        </div> -->
      </a-tab-pane>
      <a-tab-pane tab="按部门" key="2">
        <a-spin tip="加载中..." :spinning="loadingData">
          <a-table :columns="columnsb" size="middle" :dataSource="tableDatas" :pagination="false" :customHeaderRow="groupNameChange" :scroll="{ x: 9000 }" :bordered="true" rowKey="groupId" />
        </a-spin>
        <!-- 分页部分 -->
        <div class="pageClass">
          <a-pagination :total="pageTotal" :showTotal="total => `共 ${pageTotal} 条`" showSizeChanger showQuickJumper @change="pageChange" @showSizeChange="pageSizePage" />
        </div>
      </a-tab-pane>
      <a-tab-pane tab="按顾问" key="3">
        <a-spin tip="加载中..." :spinning="loadingData">
          <a-table :columns="columnsc" size="middle" :dataSource="tableDatas" :pagination="false" :customHeaderRow="gwNickNameChange" :scroll="{ x: 9000 }" :bordered="true" rowKey="groupId" />
        </a-spin>
        <!-- 分页部分 -->
        <div class="pageClass">
          <a-pagination :total="pageTotal" :showTotal="total => `共 ${pageTotal} 条`" showSizeChanger showQuickJumper @change="pageChange" @showSizeChange="pageSizePage" />
        </div>
      </a-tab-pane>
    </a-tabs>
    <!-- 底部指标说明 -->
    <div>
      <a-list size="small" :split="false" :data-source="indicatorData">
        <a-list-item slot="renderItem" slot-scope="item">
          {{ item }}
        </a-list-item>
        <div slot="header" style="fontSize: 20px;">
          指标说明：
        </div>
      </a-list>
    </div>
    <a-modal
      :width="700"
      title="分成核算"
      :visible="accountingObj.visible"
      @ok="accountingConfirm"
      @cancel="accountingObj.visible=false"
      destroyOnClose
      :confirmLoading="accountingObj.loading"
    >
      <a-form-model
        :model="accountingObj.form"
        ref="accountingForm"
        :rules="accountingObj.rules"
        :labelCol="{span:5}"
        :wrapperCol="{span:10}"
      >
        <a-form-model-item label="年份" prop="year">
          <a-date-picker v-model="accountingObj.form.year" picker="year" mode="year" format="YYYY" placeholder="请选择年份"
          :open="accountingObj.yearFlag"
          @focus="accountingObj.yearFlag=true"
           @panelChange="yearChange"/>
        </a-form-model-item>
        <a-form-model-item label="月份" prop="month">
          <a-date-picker v-model="accountingObj.form.month" picker="month" mode="month" format="MM" placeholder="请选择月份"
          :open="accountingObj.monthFlag"
          @focus="accountingObj.monthFlag=true"
          @panelChange="monthChange"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getCompany,
  getGroup,
  getCounselor,
  getSalesData,
  exportSalelistdata,
  exportAimsTem,
  mateRuleGenerateSalesPercentage
} from '../../server/salesList'
import { mapGetters } from 'vuex'
import fileDownload from 'js-file-download' // 导出文件插件

export default {
  name: 'SalesList',
  data() {
    return {
      // 默认搜索框model数据
      insetData: {
        filialeType: undefined,
        groupeType: undefined,
        counselorType: undefined
      },
      startDate: undefined, // 月初
      endDate: undefined, // 当前日期
      companyArr: [], // 区域分公司数组
      groupArr: [], // 组别数组
      counselorArr: [], // 顾问数组
      tableObject: { // 搜索传参数据
        page: 1,
        rows: 10,
        condition: {
          sysId: '',
          type: 1
        }
      },
      tenantId: '', // 租户id
      // 区域表头
      columnsa: [
        {
          title: '分公司',
          dataIndex: 'branchNames',
          key: 'branchNames',
          width: 200,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '服务费',
          children: [
            {
              title: '①服务费目标',
              dataIndex: 'mAims',
              key: 'mAims',
              align: 'center'
            },
            {
              title: '②服务费',
              dataIndex: 'serveexp',
              key: 'serveexp',
              align: 'center'
            },

            {
              title: '③分入业绩',
              dataIndex: 'frsMoney',
              key: 'frsMoney',
              align: 'center'
            },

            {
              title: '④分出业绩',
              dataIndex: 'fcsMoney',
              key: 'fcsMoney',
              align: 'center'
            },

            {
              title: '⑤服务费完成率',
              dataIndex: 'mAimsRate',
              key: 'mAimsRate',
              align: 'center'
            },
            {
              title: '⑥利润业绩目标',
              dataIndex: 'yAims',
              key: 'yAims',
              align: 'center'
            },
            {
              title: '⑦当月签单利润业绩',
              dataIndex: 'lrServeexp',
              key: 'lrServeexp',
              align: 'center'
            },

            {
              title: '⑧分入利润业绩',
              dataIndex: 'frMoney',
              key: 'frMoney',
              align: 'center'
            },

            {
              title: '⑨分出利润业绩',
              dataIndex: 'fcMoney',
              key: 'fcMoney',
              align: 'center'
            },

            {
              title: '⑩利润业绩完成率',
              dataIndex: 'yAimsRate',
              key: 'yAimsRate',
              align: 'center'
            },
            {
              title: '⑪订单服务费',
              dataIndex: 'orderRealserveexp',
              key: 'orderRealserveexp',
              align: 'center'
            },
            {
              title: '⑫退款服务费',
              dataIndex: 'refundServeexp',
              key: 'refundServeexp',
              align: 'center'
            },
            {
              title: '⑬风险代理退款订单量',
              dataIndex: 'fxCount',
              key: 'fxCount',
              align: 'center'
            },
            {
              title: '⑭风险代理退款服务费',
              dataIndex: 'refxServeexp',
              key: 'refxServeexp',
              align: 'center'
            }
          ]
        },
        {
          title: '服务费业务构成（一级分类）',
          children: [
            {
              title: '商标服务费',
              dataIndex: 'trOneServeexp',
              key: 'trOneServeexp',
              align: 'center'
            },
            {
              title: '版权服务费',
              dataIndex: 'crOneServeexp',
              key: 'crOneServeexp',
              align: 'center'
            },
            {
              title: '专利服务费',
              dataIndex: 'ptOneServeexp',
              key: 'ptOneServeexp',
              align: 'center'
            },
            {
              title: '涉外商标服务费',
              dataIndex: 'swTrOneServeexp',
              key: 'swTrOneServeexp',
              align: 'center'
            },
            {
              title: '涉外专利服务费',
              dataIndex: 'swPtOneServeexp',
              key: 'swPtOneServeexp',
              align: 'center'
            },
            {
              title: '项目财税服务费',
              dataIndex: 'xmCsOneServeexp',
              key: 'xmCsOneServeexp',
              align: 'center'
            },
            {
              title: '法律服务费',
              dataIndex: 'flOneServeexp',
              key: 'flOneServeexp',
              align: 'center'
            }
          ]
        },
        {
          title: '部分重要二级产品分类服务费',
          children: [
            {
              title: '公益商标注册',
              dataIndex: 'trSubServeexp',
              key: 'trSubServeexp',
              align: 'center'
            },
            {
              title: '风险代理',
              dataIndex: 'fxSubServeexp',
              key: 'fxSubServeexp',
              align: 'center'
            },
            {
              title: '发明专利',
              dataIndex: 'fmptSubServeexp',
              key: 'fmptSubServeexp',
              align: 'center'
            },
            {
              title: '实用新型专利',
              dataIndex: 'syptSubServeexp',
              key: 'syptSubServeexp',
              align: 'center'
            },
            {
              title: '外观设计专利',
              dataIndex: 'wgptSubServeexp',
              key: 'wgptSubServeexp',
              align: 'center'
            },
            {
              title: '项目',
              dataIndex: 'xmSubServeexp',
              key: 'xmSubServeexp',
              align: 'center'
            },
            {
              title: '财税',
              dataIndex: 'csSubServeexp',
              key: 'csSubServeexp',
              align: 'center'
            }
          ]
        },
        {
          title: '订单量业务线构成（一级分类）',
          children: [
            {
              title: '商标订单量',
              dataIndex: 'trOneSum',
              key: 'trOneSum',
              align: 'center'
            },
            {
              title: '版权订单量',
              dataIndex: 'crOneNum',
              key: 'crOneNum',
              align: 'center'
            },
            {
              title: '专利订单量',
              dataIndex: 'ptOneSum',
              key: 'ptOneSum',
              align: 'center'
            },
            {
              title: '涉外商标订单量',
              dataIndex: 'swTrOneNum',
              key: 'swTrOneNum',
              align: 'center'
            },
            {
              title: '涉外专利订单量',
              dataIndex: 'swPtOneNum',
              key: 'swPtOneNum',
              align: 'center'
            },
            {
              title: '项目财税订单量',
              dataIndex: 'xmCsOneNum',
              key: 'xmCsOneNum',
              align: 'center'
            },
            {
              title: '法律订单量',
              dataIndex: 'flOneNum',
              key: 'flOneNum',
              align: 'center'
            }
          ]
        },
        {
          title: '部分重要二级产品分类订单量',
          children: [
            {
              title: '公益商标注册',
              dataIndex: 'trSubNum',
              key: 'trSubNum',
              align: 'center'
            },
            {
              title: '风险代理',
              dataIndex: 'fxSubNum',
              key: 'fxSubNum',
              align: 'center'
            },
            {
              title: '发明专利',
              dataIndex: 'fmptSubNum',
              key: 'fmptSubNum',
              align: 'center'
            },
            {
              title: '实用新型专利',
              dataIndex: 'syptSubNum',
              key: 'syptSubNum',
              align: 'center'
            },
            {
              title: '外观设计专利',
              dataIndex: 'wgptSubNum',
              key: 'wgptSubNum',
              align: 'center'
            },
            {
              title: '项目',
              dataIndex: 'xmSubNum',
              key: 'xmSubNum',
              align: 'center'
            },
            {
              title: '财税',
              dataIndex: 'csSubNum',
              key: 'csSubNum',
              align: 'center'
            }
          ]
        },
        {
          title: '客户构成数据',
          children: [
            {
              title: '成交客户数',
              dataIndex: 'userNum',
              key: 'userNum',
              align: 'center'
            },
            {
              title: '新成交客户数',
              dataIndex: 'firstUserCount',
              key: 'firstUserCount',
              align: 'center'
            },
            {
              title: '复购客户数',
              dataIndex: 'repuUserCount',
              key: 'repuUserCount',
              align: 'center'
            },
            {
              title: '订单服务费',
              dataIndex: 'orderRealserveexp',
              key: 'orderRealserveexpa',
              align: 'center'
            },
            {
              title: '新成交客户服务费',
              dataIndex: 'firstUserServeexp',
              key: 'firstUserServeexp',
              align: 'center'
            },
            {
              title: '复购客户服务费',
              dataIndex: 'repuUserServeexp',
              key: 'repuUserServeexp',
              align: 'center'
            },
            {
              title: '订单量',
              dataIndex: 'orderNum',
              key: 'orderNum',
              align: 'center'
            },
            {
              title: '新成交客户订单量',
              dataIndex: 'firstOrderCount',
              key: 'firstOrderCount',
              align: 'center'
            },
            {
              title: '复购客户订单量',
              dataIndex: 'repuOrderCount',
              key: 'repuOrderCount',
              align: 'center'
            }
          ]
        },
        {
          title: '客户转化数据',
          children: [
            {
              title: '意向客户数',
              dataIndex: 'customerNum',
              key: 'customerNum',
              align: 'center'
            },
            {
              title: '意向客户成交数',
              dataIndex: 'closCustomerNum',
              key: 'closCustomerNum',
              align: 'center'
            },
            {
              title: '⑪转化率',
              dataIndex: 'converRates',
              key: 'converRates',
              align: 'center'
            },
            {
              title: '顾问注册意向客户数',
              dataIndex: 'gwCustomerNum',
              key: 'gwCustomerNum',
              align: 'center'
            },
            {
              title: '顾问注册转化',
              dataIndex: 'gwClosCustomerNum',
              key: 'gwClosCustomerNum',
              align: 'center'
            },
            {
              title: '⑫顾问注册转化率',
              dataIndex: 'gwConverRates',
              key: 'gwConverRates',
              align: 'center'
            },
            {
              title: '自然注册意向客户数',
              dataIndex: 'zrCustomerNum',
              key: 'zrCustomerNum',
              align: 'center'
            },
            {
              title: '自然注册转化',
              dataIndex: 'zrClosCustomerNum',
              key: 'zrClosCustomerNum',
              align: 'center'
            },
            {
              title: '⑬自然注册转化率',
              dataIndex: 'zrConverRates',
              key: 'zrConverRates',
              align: 'center'
            }
          ]
        }
      ],
      // 组别表头
      columnsb: [
        {
          title: '分公司',
          dataIndex: 'branchNames',
          key: 'branchNames',
          width: 200,
          fixed: 'left',
          align: 'center'
        },
        {
          title: ' 小组',
          dataIndex: 'groupNames',
          key: 'groupNames',
          width: 200,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '服务费',
          children: [
            {
              title: '①服务费目标',
              dataIndex: 'mAims',
              key: 'mAims',
              align: 'center'
            },
            {
              title: '②服务费',
              dataIndex: 'serveexp',
              key: 'serveexp',
              align: 'center'
            },
            {
              title: '③服务费完成率',
              dataIndex: 'mAimsRate',
              key: 'mAimsRate',
              align: 'center'
            },
            {
              title: '④利润业绩目标',
              dataIndex: 'yAims',
              key: 'yAims',
              align: 'center'
            },
            {
              title: '⑤当月签单利润业绩',
              dataIndex: 'lrServeexp',
              key: 'lrServeexp',
              align: 'center'
            },
            {
              title: '⑥利润业绩完成率',
              dataIndex: 'yAimsRate',
              key: 'yAimsRate',
              align: 'center'
            },
            {
              title: '⑦订单服务费',
              dataIndex: 'orderRealserveexp',
              key: 'orderRealserveexp',
              align: 'center'
            },
            {
              title: '⑧退款服务费',
              dataIndex: 'refundServeexp',
              key: 'refundServeexp',
              align: 'center'
            },
            {
              title: '⑨风险代理退款订单量',
              dataIndex: 'fxCount',
              key: 'fxCount',
              align: 'center'
            },
            {
              title: '⑩风险代理退款服务费',
              dataIndex: 'refxServeexp',
              key: 'refxServeexp',
              align: 'center'
            }
          ]
        },
        {
          title: '服务费业务构成（一级分类）',
          children: [
            {
              title: '商标服务费',
              dataIndex: 'trOneServeexp',
              key: 'trOneServeexp',
              align: 'center'
            },
            {
              title: '版权服务费',
              dataIndex: 'crOneServeexp',
              key: 'crOneServeexp',
              align: 'center'
            },
            {
              title: '专利服务费',
              dataIndex: 'ptOneServeexp',
              key: 'ptOneServeexp',
              align: 'center'
            },
            {
              title: '涉外商标服务费',
              dataIndex: 'swTrOneServeexp',
              key: 'swTrOneServeexp',
              align: 'center'
            },
            {
              title: '涉外专利服务费',
              dataIndex: 'swPtOneServeexp',
              key: 'swPtOneServeexp',
              align: 'center'
            },
            {
              title: '项目财税服务费',
              dataIndex: 'xmCsOneServeexp',
              key: 'xmCsOneServeexp',
              align: 'center'
            },
            {
              title: '法律服务费',
              dataIndex: 'flOneServeexp',
              key: 'flOneServeexp',
              align: 'center'
            }
          ]
        },
        {
          title: '部分重要二级产品分类服务费',
          children: [
            {
              title: '公益商标注册',
              dataIndex: 'trSubServeexp',
              key: 'trSubServeexp',
              align: 'center'
            },
            {
              title: '风险代理',
              dataIndex: 'fxSubServeexp',
              key: 'fxSubServeexp',
              align: 'center'
            },
            {
              title: '发明专利',
              dataIndex: 'fmptSubServeexp',
              key: 'fmptSubServeexp',
              align: 'center'
            },
            {
              title: '实用新型专利',
              dataIndex: 'syptSubServeexp',
              key: 'syptSubServeexp',
              align: 'center'
            },
            {
              title: '外观设计专利',
              dataIndex: 'wgptSubServeexp',
              key: 'wgptSubServeexp',
              align: 'center'
            },
            {
              title: '项目',
              dataIndex: 'xmSubServeexp',
              key: 'xmSubServeexp',
              align: 'center'
            },
            {
              title: '财税',
              dataIndex: 'csSubServeexp',
              key: 'csSubServeexp',
              align: 'center'
            }
          ]
        },
        {
          title: '订单量业务线构成（一级分类）',
          children: [
            {
              title: '商标订单量',
              dataIndex: 'trOneSum',
              key: 'trOneSum',
              align: 'center'
            },
            {
              title: '版权订单量',
              dataIndex: 'crOneNum',
              key: 'crOneNum',
              align: 'center'
            },
            {
              title: '专利订单量',
              dataIndex: 'ptOneSum',
              key: 'ptOneSum',
              align: 'center'
            },
            {
              title: '涉外商标订单量',
              dataIndex: 'swTrOneNum',
              key: 'swTrOneNum',
              align: 'center'
            },
            {
              title: '涉外专利订单量',
              dataIndex: 'swPtOneNum',
              key: 'swPtOneNum',
              align: 'center'
            },
            {
              title: '项目财税订单量',
              dataIndex: 'xmCsOneNum',
              key: 'xmCsOneNum',
              align: 'center'
            },
            {
              title: '法律订单量',
              dataIndex: 'flOneNum',
              key: 'flOneNum',
              align: 'center'
            }
          ]
        },
        {
          title: '部分重要二级产品分类订单量',
          children: [
            {
              title: '公益商标注册',
              dataIndex: 'trSubNum',
              key: 'trSubNum',
              align: 'center'
            },
            {
              title: '风险代理',
              dataIndex: 'fxSubNum',
              key: 'fxSubNum',
              align: 'center'
            },
            {
              title: '发明专利',
              dataIndex: 'fmptSubNum',
              key: 'fmptSubNum',
              align: 'center'
            },
            {
              title: '实用新型专利',
              dataIndex: 'syptSubNum',
              key: 'syptSubNum',
              align: 'center'
            },
            {
              title: '外观设计专利',
              dataIndex: 'wgptSubNum',
              key: 'wgptSubNum',
              align: 'center'
            },
            {
              title: '项目',
              dataIndex: 'xmSubNum',
              key: 'xmSubNum',
              align: 'center'
            },
            {
              title: '财税',
              dataIndex: 'csSubNum',
              key: 'csSubNum',
              align: 'center'
            }
          ]
        },
        {
          title: '客户构成数据',
          children: [
            {
              title: '成交客户数',
              dataIndex: 'userNum',
              key: 'userNum',
              align: 'center'
            },
            {
              title: '新成交客户数',
              dataIndex: 'firstUserCount',
              key: 'firstUserCount',
              align: 'center'
            },
            {
              title: '复购客户数',
              dataIndex: 'repuUserCount',
              key: 'repuUserCount',
              align: 'center'
            },
            {
              title: '订单服务费',
              dataIndex: 'orderRealserveexp',
              key: 'orderRealserveexpa',
              align: 'center'
            },
            {
              title: '新成交客户服务费',
              dataIndex: 'firstUserServeexp',
              key: 'firstUserServeexp',
              align: 'center'
            },
            {
              title: '复购客户服务费',
              dataIndex: 'repuUserServeexp',
              key: 'repuUserServeexp',
              align: 'center'
            },
            {
              title: '订单量',
              dataIndex: 'orderNum',
              key: 'orderNum',
              align: 'center'
            },
            {
              title: '新成交客户订单量',
              dataIndex: 'firstOrderCount',
              key: 'firstOrderCount',
              align: 'center'
            },
            {
              title: '复购客户订单量',
              dataIndex: 'repuOrderCount',
              key: 'repuOrderCount',
              align: 'center'
            }
          ]
        },
        {
          title: '客户转化数据',
          children: [
            {
              title: '意向客户数',
              dataIndex: 'customerNum',
              key: 'customerNum',
              align: 'center'
            },
            {
              title: '意向客户成交数',
              dataIndex: 'closCustomerNum',
              key: 'closCustomerNum',
              align: 'center'
            },
            {
              title: '⑪转化率',
              dataIndex: 'converRates',
              key: 'converRates',
              align: 'center'
            },
            {
              title: '顾问注册意向客户数',
              dataIndex: 'gwCustomerNum',
              key: 'gwCustomerNum',
              align: 'center'
            },
            {
              title: '顾问注册转化',
              dataIndex: 'gwClosCustomerNum',
              key: 'gwClosCustomerNum',
              align: 'center'
            },
            {
              title: '⑫顾问注册转化率',
              dataIndex: 'gwConverRates',
              key: 'gwConverRates',
              align: 'center'
            },
            {
              title: '自然注册意向客户数',
              dataIndex: 'zrCustomerNum',
              key: 'zrCustomerNum',
              align: 'center'
            },
            {
              title: '自然注册转化',
              dataIndex: 'zrClosCustomerNum',
              key: 'zrClosCustomerNum',
              align: 'center'
            },
            {
              title: '⑬自然注册转化率',
              dataIndex: 'zrConverRates',
              key: 'zrConverRates',
              align: 'center'
            }
          ]
        }
      ],
      // 顾问表头
      columnsc: [
        {
          title: '分公司',
          dataIndex: 'branchNames',
          key: 'branchNames',
          width: 200,
          fixed: 'left',
          align: 'center'
        },
        {
          title: ' 小组',
          dataIndex: 'groupNames',
          key: 'groupNames',
          width: 200,
          fixed: 'left',
          align: 'center'
        },
        {
          title: ' 顾问',
          dataIndex: 'nickName',
          key: 'nickName',
          width: 200,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '服务费',
          children: [
            {
              title: '①服务费目标',
              dataIndex: 'mAims',
              key: 'mAims',
              align: 'center'
            },
            {
              title: '②服务费',
              dataIndex: 'serveexp',
              key: 'serveexp',
              align: 'center'
            },
            {
              title: '③服务费完成率',
              dataIndex: 'mAimsRate',
              key: 'mAimsRate',
              align: 'center'
            },
            {
              title: '④利润业绩目标',
              dataIndex: 'yAims',
              key: 'yAims',
              align: 'center'
            },
            {
              title: '⑤当月签单利润业绩',
              dataIndex: 'lrServeexp',
              key: 'lrServeexp',
              align: 'center'
            },
            {
              title: '⑥利润业绩完成率',
              dataIndex: 'yAimsRate',
              key: 'yAimsRate',
              align: 'center'
            },
            {
              title: '⑦订单服务费',
              dataIndex: 'orderRealserveexp',
              key: 'orderRealserveexp',
              align: 'center'
            },
            {
              title: '⑧退款服务费',
              dataIndex: 'refundServeexp',
              key: 'refundServeexp',
              align: 'center'
            },
            {
              title: '⑨风险代理退款订单量',
              dataIndex: 'fxCount',
              key: 'fxCount',
              align: 'center'
            },
            {
              title: '⑩风险代理退款服务费',
              dataIndex: 'refxServeexp',
              key: 'refxServeexp',
              align: 'center'
            },
            {
              title: '⑪老客户提成比例',
              dataIndex: 'oldPercentage',
              key: 'oldPercentage',
              align: 'center',
              customRender: (v) => v ? v + '%' : ''
            },
            {
              title: '⑫老客户提成金额',
              dataIndex: 'oldPercentageMoney',
              key: 'oldPercentageMoney',
              align: 'center'
            },
            {
              title: '⑬新客户提成比例',
              dataIndex: 'newPercentage',
              key: 'newPercentage',
              align: 'center',
              customRender: (v) => v ? v + '%' : ''
            },
            {
              title: '⑭新客户提成金额',
              dataIndex: 'newPercentageMoney',
              key: 'newPercentageMoney',
              align: 'center'
            },
            {
              title: '⑮当月提成总额',
              dataIndex: 'percentageMoney',
              key: 'percentageMoney',
              align: 'center'
            }
          ]
        },
        {
          title: '服务费业务构成（一级分类）',
          children: [
            {
              title: '商标服务费',
              dataIndex: 'trOneServeexp',
              key: 'trOneServeexp',
              align: 'center'
            },
            {
              title: '版权服务费',
              dataIndex: 'crOneServeexp',
              key: 'crOneServeexp',
              align: 'center'
            },
            {
              title: '专利服务费',
              dataIndex: 'ptOneServeexp',
              key: 'ptOneServeexp',
              align: 'center'
            },
            {
              title: '涉外商标服务费',
              dataIndex: 'swTrOneServeexp',
              key: 'swTrOneServeexp',
              align: 'center'
            },
            {
              title: '涉外专利服务费',
              dataIndex: 'swPtOneServeexp',
              key: 'swPtOneServeexp',
              align: 'center'
            },
            {
              title: '项目财税服务费',
              dataIndex: 'xmCsOneServeexp',
              key: 'xmCsOneServeexp',
              align: 'center'
            },
            {
              title: '法律服务费',
              dataIndex: 'flOneServeexp',
              key: 'flOneServeexp',
              align: 'center'
            }
          ]
        },
        {
          title: '部分重要二级产品分类服务费',
          children: [
            {
              title: '公益商标注册',
              dataIndex: 'trSubServeexp',
              key: 'trSubServeexp',
              align: 'center'
            },
            {
              title: '风险代理',
              dataIndex: 'fxSubServeexp',
              key: 'fxSubServeexp',
              align: 'center'
            },
            {
              title: '发明专利',
              dataIndex: 'fmptSubServeexp',
              key: 'fmptSubServeexp',
              align: 'center'
            },
            {
              title: '实用新型专利',
              dataIndex: 'syptSubServeexp',
              key: 'syptSubServeexp',
              align: 'center'
            },
            {
              title: '外观设计专利',
              dataIndex: 'wgptSubServeexp',
              key: 'wgptSubServeexp',
              align: 'center'
            },
            {
              title: '项目',
              dataIndex: 'xmSubServeexp',
              key: 'xmSubServeexp',
              align: 'center'
            },
            {
              title: '财税',
              dataIndex: 'csSubServeexp',
              key: 'csSubServeexp',
              align: 'center'
            }
          ]
        },
        {
          title: '订单量业务线构成（一级分类）',
          children: [
            {
              title: '商标订单量',
              dataIndex: 'trOneSum',
              key: 'trOneSum',
              align: 'center'
            },
            {
              title: '版权订单量',
              dataIndex: 'crOneNum',
              key: 'crOneNum',
              align: 'center'
            },
            {
              title: '专利订单量',
              dataIndex: 'ptOneSum',
              key: 'ptOneSum',
              align: 'center'
            },
            {
              title: '涉外商标订单量',
              dataIndex: 'swTrOneNum',
              key: 'swTrOneNum',
              align: 'center'
            },
            {
              title: '涉外专利订单量',
              dataIndex: 'swPtOneNum',
              key: 'swPtOneNum',
              align: 'center'
            },
            {
              title: '项目财税订单量',
              dataIndex: 'xmCsOneNum',
              key: 'xmCsOneNum',
              align: 'center'
            },
            {
              title: '法律订单量',
              dataIndex: 'flOneNum',
              key: 'flOneNum',
              align: 'center'
            }
          ]
        },
        {
          title: '部分重要二级产品分类订单量',
          children: [
            {
              title: '公益商标注册',
              dataIndex: 'trSubNum',
              key: 'trSubNum',
              align: 'center'
            },
            {
              title: '风险代理',
              dataIndex: 'fxSubNum',
              key: 'fxSubNum',
              align: 'center'
            },
            {
              title: '发明专利',
              dataIndex: 'fmptSubNum',
              key: 'fmptSubNum',
              align: 'center'
            },
            {
              title: '实用新型专利',
              dataIndex: 'syptSubNum',
              key: 'syptSubNum',
              align: 'center'
            },
            {
              title: '外观设计专利',
              dataIndex: 'wgptSubNum',
              key: 'wgptSubNum',
              align: 'center'
            },
            {
              title: '项目',
              dataIndex: 'xmSubNum',
              key: 'xmSubNum',
              align: 'center'
            },
            {
              title: '财税',
              dataIndex: 'csSubNum',
              key: 'csSubNum',
              align: 'center'
            }
          ]
        },
        {
          title: '客户构成数据',
          children: [
            {
              title: '成交客户数',
              dataIndex: 'userNum',
              key: 'userNum',
              align: 'center'
            },
            {
              title: '新成交客户数',
              dataIndex: 'firstUserCount',
              key: 'firstUserCount',
              align: 'center'
            },
            {
              title: '复购客户数',
              dataIndex: 'repuUserCount',
              key: 'repuUserCount',
              align: 'center'
            },
            {
              title: '订单服务费',
              dataIndex: 'orderRealserveexp',
              key: 'orderRealserveexpa',
              align: 'center'
            },
            {
              title: '新成交客户服务费',
              dataIndex: 'firstUserServeexp',
              key: 'firstUserServeexp',
              align: 'center'
            },
            {
              title: '复购客户服务费',
              dataIndex: 'repuUserServeexp',
              key: 'repuUserServeexp',
              align: 'center'
            },
            {
              title: '订单量',
              dataIndex: 'orderNum',
              key: 'orderNum',
              align: 'center'
            },
            {
              title: '新成交客户订单量',
              dataIndex: 'firstOrderCount',
              key: 'firstOrderCount',
              align: 'center'
            },
            {
              title: '复购客户订单量',
              dataIndex: 'repuOrderCount',
              key: 'repuOrderCount',
              align: 'center'
            }
          ]
        },
        {
          title: '客户转化数据',
          children: [
            {
              title: '意向客户数',
              dataIndex: 'customerNum',
              key: 'customerNum',
              align: 'center'
            },
            {
              title: '意向客户成交数',
              dataIndex: 'closCustomerNum',
              key: 'closCustomerNum',
              align: 'center'
            },
            {
              title: '⑪转化率',
              dataIndex: 'converRates',
              key: 'converRates',
              align: 'center'
            },
            {
              title: '顾问注册意向客户数',
              dataIndex: 'gwCustomerNum',
              key: 'gwCustomerNum',
              align: 'center'
            },
            {
              title: '顾问注册转化',
              dataIndex: 'gwClosCustomerNum',
              key: 'gwClosCustomerNum',
              align: 'center'
            },
            {
              title: '⑫顾问注册转化率',
              dataIndex: 'gwConverRates',
              key: 'gwConverRates',
              align: 'center'
            },
            {
              title: '自然注册意向客户数',
              dataIndex: 'zrCustomerNum',
              key: 'zrCustomerNum',
              align: 'center'
            },
            {
              title: '自然注册转化',
              dataIndex: 'zrClosCustomerNum',
              key: 'zrClosCustomerNum',
              align: 'center'
            },
            {
              title: '⑬自然注册转化率',
              dataIndex: 'zrConverRates',
              key: 'zrConverRates',
              align: 'center'
            }
          ]
        }
      ],
      indicatorData: [
        '①、服务费目标 = 每月设置的服务费目标',
        '②、服务费 = 订单服务费-退款服务费',
        '③、服务费完成率 =（服务费/服务费目标）* 100%',
        '④、利润业绩目标 = 每月设置的利润业绩目标',
        '⑤、当月签单利润业绩利润业绩 = 订单利润业绩-退款利润业绩（订单利润业绩=订单金额-官费-渠道费-保险成本-业务线收入-流程收入-保险收入-集团预留-税费）',
        '⑥、利润业绩完成率 =（利润业绩/利润业绩目标）* 100%',
        '⑦、订单服务费 = 订单金额-官费-渠道费-保险成本-税费',
        '⑧ 、退款服务费 = 发生退款的订单服务费',
        '⑨、风险代理退款订单量 = 商标品牌咨询服务发生退款的订单数量',
        '⑩、风险代理退款服务费 = 商标品牌咨询服务发生退款的订单服务费',
        '⑪、转化率 = (意向客户成交数/意向客户数) * 100%',
        '⑫、顾问注册转化率 = (顾问注册转化/顾问注册意向客户数) * 100%',
        '⑬、自然注册转化率 = (自然注册转化/自然注册意向客户数) * 100%'
      ], // 指标说明数据
      tableData: [], // 分公司表格数据
      tableDatas: [], // 顾问表格数据
      monthDataList: [],
      pageTotal: 0, // 数据数量
      dateFormat: 'YYYY-MM-DD', // 日期显示格式
      loadingData: true, // 是否加载
      uploadUrl: `${process.env.VUE_APP_REGISTERAPI}/biOrderBaseMid/importAimsTem`,
      uploadRatioUrl: `${process.env.VUE_APP_REGISTERAPI}/biOrderBaseMid/importBiSalesPercentageRule`,
      isShowBtn: false,
      accountingObj: {
        visible: false,
        loading: false,
        form: {
          year: '',
          month: ''
        },
        rules: {
          year: { required: true, message: '请选择年份', trigger: 'change' },
          month: { required: true, message: '请选择月份', trigger: 'change' }
        },
        yearFlag: false,
        monthFlag: false
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.getNowFormatDate()
    this.getCurrentMonthFirst()
    this.tableObject.condition.sysId = this.userInfo.id
    this.tableObject.condition.tenantId = this.userInfo.tenantId
    this.tenantId = this.userInfo.tenantId
    this.tableObject.condition.updatetimeS = this.startDate
    this.tableObject.condition.updatetimeE = this.endDate
    if (this.tableObject.condition.sysId) {
      // 进入页面默认调一次表格接口
      this.getSalesDatas(this.tableObject)
    }
    const nowDate = new Date().getMonth() + 2
    for (let i = 1; i < nowDate; i++) {
      this.monthDataList.push(i)
    }
    // 判断账号是否可见导入导出按钮----仅紫竹果可见
    if (Number(this.userInfo.id) === 1328) {
      this.isShowBtn = true
    }
  },
  methods: {
    moment,
    // 获取当前月第一天
    getCurrentMonthFirst() {
      const date = new Date()
      date.setDate(1)
      const seperator1 = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      this.startDate = year + seperator1 + month + seperator1 + strDate
    },
    // 获取当前日期
    getNowFormatDate() {
      const date = new Date()
      const seperator1 = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      this.endDate = year + seperator1 + month + seperator1 + strDate
    },
    // 顾问区域分公司change事件
    filialeChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.company = val
        const datas = {
          sysId: this.userInfo.id,
          company: val
        }
        getGroup(datas).then(res => {
          if (res) {
            this.groupArr = res.data.groupList
          }
        }).catch(err => {
          console.log(err)
        })
      }
      // else {
      //   alert(11)
      //   this.groupArr = []
      //   this.counselorArr = []
      //   this.insetData.groupeType = undefined
      //   this.insetData.counselorType = undefined
      //   delete this.tableObject.condition.company
      //   delete this.tableObject.condition.sysPramId
      //   delete this.tableObject.condition.groupId
      // }
    },
    // 顾问区域分公司获得焦点事件
    filialeFocus() {
      const datas = {
        sysId: this.userInfo.id
      }
      getCompany(datas).then(res => {
        if (res) {
          this.companyArr = res.data.companyList
        }
      }).catch((err) => {
        console.log(err, '分公司')
      })
    },
    // 顾问组别change事件
    groupeChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.groupId = val
        const datas = {
          sysId: this.userInfo.id,
          groupId: val
        }
        getCounselor(datas).then(res => {
          if (res) {
            this.counselorArr = res.data.gwList
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.counselorArr = []
        this.insetData.counselorType = undefined
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
      }
    },
    // 顾问姓名change事件
    counselorChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.sysPramId = val // 赋值顾问id
      } else {
        delete this.tableObject.condition.sysPramId
      }
    },
    // 月份点击事件
    changeMonthClick(val) {
      this.packagingClick(val)
    },
    // 封装月份事件
    packagingClick(val) {
      const nowdays = new Date()
      const year = nowdays.getFullYear()
      let month = val
      if (month < 10) {
        month = '0' + month
      }
      const myDate = new Date(year, month, 0)
      const startDate = year + '-' + month + '-01' // 上个月第一天
      const endDate = year + '-' + month + '-' + myDate.getDate() // 上个月最后一天
      this.tableObject.condition.updatetimeS = startDate
      this.tableObject.condition.updatetimeE = endDate
      this.startDate = startDate
      this.endDate = endDate
      this.getSalesDatas(this.tableObject)
    },
    // 不可选择的日期控制
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    // 自定义时间change事件
    createChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.updatetimeS = dateString[0]
        this.tableObject.condition.updatetimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        this.getNowFormatDate()
        this.getCurrentMonthFirst()
        this.tableObject.condition.updatetimeS = this.startDate
        this.tableObject.condition.updatetimeE = this.endDate
      }
    },
    // 查询按钮时间
    inquireClick(val) {
      if (val === '1') {
        this.getSalesDatas(this.tableObject)
      }
    },
    // 重置
    insetClick(val) {
      if (val === '2') {
        this.insetData = {
          filialeType: undefined,
          groupeType: undefined,
          counselorType: undefined
        }
        this.getNowFormatDate()
        this.getCurrentMonthFirst()
        this.tableObject.condition.updatetimeS = this.startDate
        this.tableObject.condition.updatetimeE = this.endDate
        delete this.tableObject.condition.company
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
        this.getSalesDatas(this.tableObject)
      }
    },
    // 导入服务费目标
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 文件,${info.file.response.msg}.`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 文件上传失败.`)
      }
    },
    // 导入服务费之前格式验证
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      if (!isJpgOrPng) {
        this.$message.error('只能上传xls/xlsx格式文件，文件不能超过2MB!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('文件不能超过2MB!')
      }
      return isJpgOrPng && isLt2M
    },
    // 导出excel
    exportClick() {
      const fileDownData = { ...this.tableObject }
      exportSalelistdata(fileDownData).then(res => {
        if (res.code) {
          this.$message.error(res.message)
        } else {
          const now = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
          fileDownload(res.data, '销售业绩统计明细' + now + '.xls')
        }
      }).catch(err => {
        console.log(err, '导出失败')
      })
    },
    // 导出服务费目标
    exportTargetClick() {
      const fileDownData = { tenantId: this.tenantId }
      exportAimsTem(fileDownData).then(res => {
        if (res.code) {
          this.$message.error(res.message)
        } else {
          const now = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
          fileDownload(res.data, '服务费目标模板' + now + '.xls')
        }
      }).catch(err => {
        console.log(err, '导出失败')
      })
    },
    // 表格数据
    getSalesDatas(val) {
      this.loadingData = true
      this.tableData = [] // 分公司数据清空
      this.tableDatas = [] // 顾问 小组数据清空
      getSalesData(val).then(res => {
        if (res.data.page.list) {
          this.tableData = [...res.data.page.list] || []
          this.tableDatas = [...res.data.page.list] || []
          this.pageTotal = res.data.page.total
          const totalDataObj = {
            branchNames: '合计',
            groupId: '019'
          }
          // 服务费合计
          totalDataObj.serveexp = this.tableData.reduce((prev, next) => {
            if (next.serveexp) {
              return prev + next.serveexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.serveexp = totalDataObj.serveexp.toFixed(2)
          // 利润业绩合计
          totalDataObj.lrServeexp = this.tableData.reduce((prev, next) => {
            if (next.lrServeexp) {
              return prev + next.lrServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.lrServeexp = totalDataObj.lrServeexp.toFixed(2)
          // 服务费目标
          totalDataObj.mAims = this.tableData.reduce((prev, next) => {
            if (next.mAims) {
              return prev + next.mAims
            } else {
              return prev
            }
          }, 0)
          totalDataObj.mAims = totalDataObj.mAims.toFixed(2)
          // 服务费完成率
          if (totalDataObj.serveexp !== '0.00' && totalDataObj.mAims !== '0.00') {
            totalDataObj.mAimsRate = Math.round(totalDataObj.serveexp / totalDataObj.mAims * 10000) / 100.00 + '%'
          } else {
            totalDataObj.mAimsRate = '-'
          }
          // 利润业绩目标
          totalDataObj.yAims = this.tableData.reduce((prev, next) => {
            if (next.yAims) {
              return prev + next.yAims
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.yAims = totalDataObj.yAims.toFixed(2)
          // 利润业绩完成率
          if (totalDataObj.lrServeexp !== '0.00' && totalDataObj.yAims !== '0.00') {
            totalDataObj.yAimsRate = Math.round(totalDataObj.lrServeexp / totalDataObj.yAims * 10000) / 100.00 + '%'
          } else {
            totalDataObj.yAimsRate = '-'
          }
          // 订单服务费合计
          totalDataObj.orderRealserveexp = this.tableData.reduce((prev, next) => {
            if (next.orderRealserveexp) {
              return prev + next.orderRealserveexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.orderRealserveexp = totalDataObj.orderRealserveexp.toFixed(2)
          // 退款服务费合计
          totalDataObj.refundServeexp = this.tableData.reduce((prev, next) => {
            if (next.refundServeexp) {
              return prev + next.refundServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.refundServeexp = totalDataObj.refundServeexp.toFixed(2)
          // 风险代理退款订单量合计
          totalDataObj.fxCount = this.tableData.reduce((prev, next) => {
            if (next.fxCount) {
              return prev + next.fxCount
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.fxCount = totalDataObj.fxCount.toFixed(2)
          // 风险代理退款服务费合计
          totalDataObj.refxServeexp = this.tableData.reduce((prev, next) => {
            if (next.refxServeexp) {
              return prev + next.refxServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.refxServeexp = totalDataObj.refxServeexp.toFixed(2)
          // 商标服务费
          totalDataObj.trOneServeexp = this.tableData.reduce((prev, next) => {
            if (next.trOneServeexp) {
              return prev + next.trOneServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.trOneServeexp = totalDataObj.trOneServeexp.toFixed(2)
          // 版权服务费
          totalDataObj.crOneServeexp = this.tableData.reduce((prev, next) => {
            if (next.crOneServeexp) {
              return prev + next.crOneServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.crOneServeexp = totalDataObj.crOneServeexp.toFixed(2)
          // 专利服务费
          totalDataObj.ptOneServeexp = this.tableData.reduce((prev, next) => {
            if (next.ptOneServeexp) {
              return prev + next.ptOneServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.ptOneServeexp = totalDataObj.ptOneServeexp.toFixed(2)
          // 涉外商标服务费
          totalDataObj.swTrOneServeexp = this.tableData.reduce((prev, next) => {
            if (next.swTrOneServeexp) {
              return prev + next.swTrOneServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.swTrOneServeexp = totalDataObj.swTrOneServeexp.toFixed(2)
          // 涉外专利服务费
          totalDataObj.swPtOneServeexp = this.tableData.reduce((prev, next) => {
            if (next.swPtOneServeexp) {
              return prev + next.swPtOneServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.swPtOneServeexp = totalDataObj.swPtOneServeexp.toFixed(2)
          // 项目财税服务费
          totalDataObj.xmCsOneServeexp = this.tableData.reduce((prev, next) => {
            if (next.xmCsOneServeexp) {
              return prev + next.xmCsOneServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.xmCsOneServeexp = totalDataObj.xmCsOneServeexp.toFixed(2)
          // 法律服务费
          totalDataObj.flOneServeexp = this.tableData.reduce((prev, next) => {
            if (next.flOneServeexp) {
              return prev + next.flOneServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.flOneServeexp = totalDataObj.flOneServeexp.toFixed(2)
          // 公益商标注册
          totalDataObj.trSubServeexp = this.tableData.reduce((prev, next) => {
            if (next.trSubServeexp) {
              return prev + next.trSubServeexp
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.trSubServeexp = totalDataObj.trSubServeexp.toFixed(2)
          // 风险代理
          totalDataObj.fxSubServeexp = this.tableData.reduce((prev, next) => {
            if (next.fxSubServeexp) {
              return prev + next.fxSubServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.fxSubServeexp = totalDataObj.fxSubServeexp.toFixed(2)
          // 发明专利
          totalDataObj.fmptSubServeexp = this.tableData.reduce((prev, next) => {
            if (next.fmptSubServeexp) {
              return prev + next.fmptSubServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.fmptSubServeexp = totalDataObj.fmptSubServeexp.toFixed(2)
          // 实用新型专利
          totalDataObj.syptSubServeexp = this.tableData.reduce((prev, next) => {
            if (next.syptSubServeexp) {
              return prev + next.syptSubServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.syptSubServeexp = totalDataObj.syptSubServeexp.toFixed(2)
          // 外观设计专利
          totalDataObj.wgptSubServeexp = this.tableData.reduce((prev, next) => {
            if (next.wgptSubServeexp) {
              return prev + next.wgptSubServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.wgptSubServeexp = totalDataObj.wgptSubServeexp.toFixed(2)
          // 项目
          totalDataObj.xmSubServeexp = this.tableData.reduce((prev, next) => {
            if (next.xmSubServeexp) {
              return prev + next.xmSubServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.xmSubServeexp = totalDataObj.xmSubServeexp.toFixed(2)
          // 财税
          totalDataObj.csSubServeexp = this.tableData.reduce((prev, next) => {
            if (next.csSubServeexp) {
              return prev + next.csSubServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.csSubServeexp = totalDataObj.csSubServeexp.toFixed(2)
          // 商标订单量
          totalDataObj.trOneSum = this.tableData.reduce((prev, next) => {
            if (next.trOneSum) {
              return prev + next.trOneSum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.trOneSum = totalDataObj.trOneSum.toFixed(2)
          // 版权订单量
          totalDataObj.crOneNum = this.tableData.reduce((prev, next) => {
            if (next.crOneNum) {
              return prev + next.crOneNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.crOneNum = totalDataObj.crOneNum.toFixed(2)
          // 专利订单量
          totalDataObj.ptOneSum = this.tableData.reduce((prev, next) => {
            if (next.ptOneSum) {
              return prev + next.ptOneSum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.ptOneSum = totalDataObj.ptOneSum.toFixed(2)
          // 涉外商标订单量
          totalDataObj.swTrOneNum = this.tableData.reduce((prev, next) => {
            if (next.swTrOneNum) {
              return prev + next.swTrOneNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.swTrOneNum = totalDataObj.swTrOneNum.toFixed(2)
          // 涉外专利订单量
          totalDataObj.swPtOneNum = this.tableData.reduce((prev, next) => {
            if (next.swPtOneNum) {
              return prev + next.swPtOneNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.swPtOneNum = totalDataObj.swPtOneNum.toFixed(2)
          // 项目财税订单量
          totalDataObj.xmCsOneNum = this.tableData.reduce((prev, next) => {
            if (next.xmCsOneNum) {
              return prev + next.xmCsOneNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.xmCsOneNum = totalDataObj.xmCsOneNum.toFixed(2)
          // 法律订单量
          totalDataObj.flOneNum = this.tableData.reduce((prev, next) => {
            if (next.flOneNum) {
              return prev + next.flOneNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.flOneNum = totalDataObj.flOneNum.toFixed(2)
          // 公益商标注册
          totalDataObj.trSubNum = this.tableData.reduce((prev, next) => {
            if (next.trSubNum) {
              return prev + next.trSubNum
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.trSubNum = totalDataObj.trSubNum.toFixed(2)
          // 风险代理
          totalDataObj.fxSubNum = this.tableData.reduce((prev, next) => {
            if (next.fxSubNum) {
              return prev + next.fxSubNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.fxSubNum = totalDataObj.fxSubNum.toFixed(2)
          // 发明专利
          totalDataObj.fmptSubNum = this.tableData.reduce((prev, next) => {
            if (next.fmptSubNum) {
              return prev + next.fmptSubNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.fmptSubNum = totalDataObj.fmptSubNum.toFixed(2)
          // 实用新型专利
          totalDataObj.syptSubNum = this.tableData.reduce((prev, next) => {
            if (next.syptSubNum) {
              return prev + next.syptSubNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.syptSubNum = totalDataObj.syptSubNum.toFixed(2)
          // 外观设计专利
          totalDataObj.wgptSubNum = this.tableData.reduce((prev, next) => {
            if (next.wgptSubNum) {
              return prev + next.wgptSubNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.wgptSubNum = totalDataObj.wgptSubNum.toFixed(2)
          // 项目
          totalDataObj.xmSubNum = this.tableData.reduce((prev, next) => {
            if (next.xmSubNum) {
              return prev + next.xmSubNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.xmSubNum = totalDataObj.xmSubNum.toFixed(2)
          // 财税
          totalDataObj.csSubNum = this.tableData.reduce((prev, next) => {
            if (next.csSubNum) {
              return prev + next.csSubNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.csSubNum = totalDataObj.csSubNum.toFixed(2)
          // 成交客户数
          totalDataObj.userNum = this.tableData.reduce((prev, next) => {
            if (next.userNum) {
              return prev + next.userNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.userNum = totalDataObj.userNum.toFixed(2)
          // 新成交客户数
          totalDataObj.firstUserCount = this.tableData.reduce((prev, next) => {
            if (next.firstUserCount) {
              return prev + next.firstUserCount
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.firstUserCount = totalDataObj.firstUserCount.toFixed(2)
          // 复购客户数
          totalDataObj.repuUserCount = this.tableData.reduce((prev, next) => {
            if (next.repuUserCount) {
              return prev + next.repuUserCount
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.repuUserCount = totalDataObj.repuUserCount.toFixed(2)
          // 订单服务费
          totalDataObj.orderRealserveexp = this.tableData.reduce((prev, next) => {
            if (next.orderRealserveexp) {
              return prev + next.orderRealserveexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.orderRealserveexp = totalDataObj.orderRealserveexp.toFixed(2)
          // 新成交客户服务费
          totalDataObj.firstUserServeexp = this.tableData.reduce((prev, next) => {
            if (next.firstUserServeexp) {
              return prev + next.firstUserServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.firstUserServeexp = totalDataObj.firstUserServeexp.toFixed(2)
          // 复购客户服务费
          totalDataObj.repuUserServeexp = this.tableData.reduce((prev, next) => {
            if (next.repuUserServeexp) {
              return prev + next.repuUserServeexp
            } else {
              return prev
            }
          }, 0) || 0
          totalDataObj.repuUserServeexp = totalDataObj.repuUserServeexp.toFixed(2)
          // 订单量
          totalDataObj.orderNum = this.tableData.reduce((prev, next) => {
            if (next.orderNum) {
              return prev + next.orderNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.orderNum = totalDataObj.orderNum.toFixed(2)
          // 新成交客户订单量
          totalDataObj.firstOrderCount = this.tableData.reduce((prev, next) => {
            if (next.firstOrderCount) {
              return prev + next.firstOrderCount
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.firstOrderCount = totalDataObj.firstOrderCount.toFixed(2)
          // 复购客户订单量
          totalDataObj.repuOrderCount = this.tableData.reduce((prev, next) => {
            if (next.repuOrderCount) {
              return prev + next.repuOrderCount
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.repuOrderCount = totalDataObj.repuOrderCount.toFixed(2)
          // 意向客户数
          totalDataObj.customerNum = this.tableData.reduce((prev, next) => {
            if (next.customerNum) {
              return prev + next.customerNum
            } else {
              return prev
            }
          }, 0) || 0
          // totalDataObj.customerNum = totalDataObj.customerNum.toFixed(2)
          // 意向客户成交数
          totalDataObj.closCustomerNum = this.tableData.reduce((prev, next) => {
            return prev + (next.closCustomerNum || 0)
          }, 0) || 0
          // totalDataObj.closCustomerNum = totalDataObj.closCustomerNum.toFixed(2)
          // 转化率
          if (totalDataObj.closCustomerNum !== 0 && totalDataObj.customerNum !== 0) {
            totalDataObj.converRates = Math.round(totalDataObj.closCustomerNum / totalDataObj.customerNum * 10000) / 100.00 + '%'
          } else {
            totalDataObj.converRates = '-'
          }
          // 顾问注册意向客户数
          totalDataObj.gwCustomerNum = this.tableData.reduce((prev, next) => {
            return prev + (next.gwCustomerNum || 0)
          }, 0) || 0
          // totalDataObj.gwCustomerNum = totalDataObj.gwCustomerNum.toFixed(2)
          // 顾问注册转化
          totalDataObj.gwClosCustomerNum = this.tableData.reduce((prev, next) => {
            return prev + (next.gwClosCustomerNum || 0)
          }, 0) || 0
          // totalDataObj.gwClosCustomerNum = totalDataObj.gwClosCustomerNum.toFixed(2)
          // 顾问注册转化率
          if (totalDataObj.gwClosCustomerNum !== 0 && totalDataObj.gwCustomerNum !== 0) {
            totalDataObj.gwConverRates = Math.round(totalDataObj.gwClosCustomerNum / totalDataObj.gwCustomerNum * 10000) / 100.00 + '%'
          } else {
            totalDataObj.gwConverRates = '-'
          }
          // 自然注册意向客户数
          totalDataObj.zrCustomerNum = this.tableData.reduce((prev, next) => {
            return prev + (next.zrCustomerNum || 0)
          }, 0) || 0
          // totalDataObj.zrCustomerNum = totalDataObj.zrCustomerNum.toFixed(2)
          // 自然注册转化
          totalDataObj.zrClosCustomerNum = this.tableData.reduce((prev, next) => {
            return prev + (next.zrClosCustomerNum || 0)
          }, 0) || 0
          // totalDataObj.zrClosCustomerNum = totalDataObj.zrClosCustomerNum.toFixed(2)
          //  自然注册转化率
          if (totalDataObj.zrClosCustomerNum !== 0 && totalDataObj.zrCustomerNum !== 0) {
            totalDataObj.zrConverRates = Math.round(totalDataObj.zrClosCustomerNum / totalDataObj.zrCustomerNum * 10000) / 100.00 + '%'
          } else {
            totalDataObj.zrConverRates = '-'
          }
          console.log('totalDataObj', totalDataObj)
          this.tableData.unshift(totalDataObj)
        }
      }).then(() => {
        this.loadingData = false
      }).catch(err => {
        console.log(err, '表格数据')
        this.loadingData = false
      })
    },
    // tab栏change事件
    tabChange(key) {
      if (key) {
        this.tableData = [] // 分公司数据清空
        this.tableDatas = [] // 顾问 小组数据清空
        this.pageTotal = 0 // 总数量为0
        this.tableObject.condition.type = key
        this.getSalesDatas(this.tableObject)
      }
    },
    // 页码改变的回调
    pageChange(page, pageSize) {
      if (page && pageSize) {
        this.tableObject.page = page
        this.tableObject.rows = pageSize
        this.getSalesDatas(this.tableObject)
      }
    },
    // 显示数据量change事件
    pageSizePage(current, size) {
      if (current && size) {
        this.tableObject.page = current
        this.tableObject.rows = size
        this.getSalesDatas(this.tableObject)
      }
    },
    // 设置分公司头部行属性
    branchNameChange(column, index) {
      // console.log(column, 'column')
      column.forEach((item, index) => {
        if (item.key === 'branchName') {
          column[index].className = 'trA'
        } else if (item.key === 1) {
          column[index].className = 'trB'
          item.children.forEach(i => {
            i.className = 'trB'
          })
        } else if (item.key === 2) {
          column[index].className = 'trC'
          item.children.forEach(i => {
            i.className = 'trC'
          })
        } else if (item.key === 3) {
          column[index].className = 'trD'
          item.children.forEach(i => {
            i.className = 'trD'
          })
        } else if (item.key === 4) {
          column[index].className = 'trE'
          item.children.forEach(i => {
            i.className = 'trE'
          })
        } else if (item.key === 5) {
          column[index].className = 'trF'
          item.children.forEach(i => {
            i.className = 'trF'
          })
        } else if (item.key === 6) {
          column[index].className = 'trG'
          item.children.forEach(i => {
            i.className = 'trG'
          })
        } else if (item.key === 7) {
          column[index].className = 'trH'
          item.children.forEach(i => {
            i.className = 'trH'
          })
        }
      })
    },
    // 设置小组头部行属性
    groupNameChange(column, index) {
      // console.log(column, 'column')
      column.forEach((item, index) => {
        if (item.key === 'groupName' || item.key === 'branchName') {
          column[index].className = 'trA'
        } else if (item.key === 2) {
          column[index].className = 'trB'
          item.children.forEach(i => {
            i.className = 'trB'
          })
        } else if (item.key === 3) {
          column[index].className = 'trC'
          item.children.forEach(i => {
            i.className = 'trC'
          })
        } else if (item.key === 4) {
          column[index].className = 'trD'
          item.children.forEach(i => {
            i.className = 'trD'
          })
        } else if (item.key === 5) {
          column[index].className = 'trE'
          item.children.forEach(i => {
            i.className = 'trE'
          })
        } else if (item.key === 6) {
          column[index].className = 'trF'
          item.children.forEach(i => {
            i.className = 'trF'
          })
        } else if (item.key === 7) {
          column[index].className = 'trG'
          item.children.forEach(i => {
            i.className = 'trG'
          })
        } else if (item.key === 8) {
          column[index].className = 'trH'
          item.children.forEach(i => {
            i.className = 'trH'
          })
        }
      })
    },
    // 设置顾问头部行属性
    gwNickNameChange(column, index) {
      column.forEach((item, index) => {
        if (item.key === 'gwNickname' || item.key === 'groupName' || item.key === 'branchName') {
          column[index].className = 'trA'
        } else if (item.key === 3) {
          column[index].className = 'trB'
          item.children.forEach(i => {
            i.className = 'trB'
          })
        } else if (item.key === 4) {
          column[index].className = 'trC'
          item.children.forEach(i => {
            i.className = 'trC'
          })
        } else if (item.key === 5) {
          column[index].className = 'trD'
          item.children.forEach(i => {
            i.className = 'trD'
          })
        } else if (item.key === 6) {
          column[index].className = 'trE'
          item.children.forEach(i => {
            i.className = 'trE'
          })
        } else if (item.key === 7) {
          column[index].className = 'trF'
          item.children.forEach(i => {
            i.className = 'trF'
          })
        } else if (item.key === 8) {
          column[index].className = 'trG'
          item.children.forEach(i => {
            i.className = 'trG'
          })
        } else if (item.key === 9) {
          column[index].className = 'trH'
          item.children.forEach(i => {
            i.className = 'trH'
          })
        }
      })
    },
    // 分成核算
    accountingOp() {
      this.accountingObj.visible = true
    },
    accountingConfirm() {
      this.$refs.accountingForm.validate(v => {
        if (v) {
          this.accountingObj.loading = true
          const params = {
            year: Number(moment(this.accountingObj.form.year).format('YYYY')),
            month: Number(moment(this.accountingObj.form.month).format('MM')),
            tenantId: this.userInfo.tenantId
          }
          mateRuleGenerateSalesPercentage(params).then(res => {
            console.log(res.data)
            if (res.data.code === 0) {
              this.$message.success(res.data.msg)
              this.accountingObj.visible = false
            }
          }).finally(() => {
            this.accountingObj.loading = false
          })
        }
      })
    },
    downRatioTemplate() {
      const a = document.createElement('a');
      a.href = window.location.origin + '/files/分成比例模板.xlsx';
      a.download = '分成比例模板'; // 必加
      a.click();
    },
    yearChange(year) {
      this.accountingObj.form.year = year
      this.accountingObj.yearFlag = false
    },
    monthChange(month) {
      this.accountingObj.form.month = month
      this.accountingObj.monthFlag = false
    }
  }
}
</script>

<style lang='scss' scoped>
  .pageClass {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    height: 80px;
  }
  .pageClass>.ant-pagination {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .exportClass {
    position: absolute;
    right: 50px;
  }
  // 分公司背景
  /deep/ .ant-table-thead > tr > .trA {
    background: #FAFAFA;
  }
  // 服务费背景
  /deep/ .ant-table-thead > tr > .trB {
    background: #E2EEDA;
  }
  // 服务费业务线构成（一级分类）背景
  /deep/ .ant-table-thead > tr > .trC {
    background: #D9E2F3;
  }
  // 部分重要二级产品服务费背景
  /deep/ .ant-table-thead > tr > .trD {
    background: #FFF2CB;
  }
  // 订单量业务线构成（一级分类）背景
  /deep/ .ant-table-thead > tr > .trE {
    background: #ECECEC;
  }
  // 部分重要二级产品分类订单量背景
  /deep/ .ant-table-thead > tr > .trF {
    background: #FBE4D5;
  }
  // 客户构成数据背景
  /deep/ .ant-table-thead > tr > .trG {
    background: #DEEAF6;
  }
  // 客户转化数据背景
  /deep/ .ant-table-thead > tr > .trH {
    background: #D9DCE1;
  }
</style>
