import { current } from '../utils/api'

// 表格数据
export function getSalesData(data) {
  return current({
    url: '/biOrderBaseMid/orderStatisticalPageList',
    method: 'post',
    data
  })
}

// 旧表格数据
export function getSalesDataOdd(data) {
  return current({
    url: '/biOrderBaseMid/orderStatisticalPageListOld',
    method: 'post',
    data
  })
}

// 分公司列表
export function getCompany(params) {
  return current({
    url: `/biRole/getGwCompany/${params.sysId}`,
    method: 'get'
  })
}

// 分组列表
export function getGroup(data) {
  return current({
    url: '/biRole/getGwGroup',
    method: 'post',
    data
  })
}

// 顾问列表
export function getCounselor(data) {
  return current({
    url: '/biRole/getGw',
    method: 'post',
    data
  })
}

// 注册用户导出
export function exportSalelistdata(data) {
  return current({
    url: '/biOrderBaseMid/exportorderStatisticalData',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 服务费目标导出
export function exportAimsTem(data) {
  return current({
    url: '/biOrderBaseMid/exportAimsTem',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 导入服务费目标
// export function importServeexp(data) {
//   return current({
//     url: '/biOrderBaseMid/importAimsTem',
//     method: 'post',
//     data,
//     responseType: 'blob'
//   })
// }
// 分成核算  计算订单提成
export function mateRuleGenerateSalesPercentage(data) {
  return current({
    url: '/biOrderBaseMid/mateRuleGenerateSalesPercentage',
    method: 'post',
    data
  })
}
